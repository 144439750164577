export const environment = {
  appName: 'Hephaestus',
  production: false,
  environmentName: 'development',
  enabledServiceWorker: true,
  firebase: {
    apiKey: 'AIzaSyDdBODm2IaW4y8ElddDGfJnz2TCmxHfiYQ',
    authDomain: 'valhalla-hephaestus-alpha.firebaseapp.com',
    projectId: 'valhalla-hephaestus-alpha',
    storageBucket: 'valhalla-hephaestus-alpha.appspot.com',
    messagingSenderId: '412080222207',
    appId: '1:412080222207:web:df8096680e9a298d42c1ca',
  },
  oauth2: {
    clientId: '729860267864-sij9l8jaclt6s4fqkdrhikogo5tkemfl.apps.googleusercontent.com',
  },
  hephaestus: {
    apiKey: 'AIzaSyCXIkAjKLQkpPXZlmt6tgx5BIPVuhu_DuA',
    baseUrl: 'https://alpha.hephaestus.cargamos.com',
  },
  swagger: {
    bucket: 'gs://valhalla-hephaestus-alpha.appspot.com/documentation/swaggers',
  },
};
